import React from "react";
import PropTypes from "prop-types";
import Layout from "@app-components/layout/";
import { css } from "@emotion/core";
import Header from "@app-components/ui/header/top_bottom/";
import MapToronto from "@media/contact/map_toronto.svg";
import Toronto from "@media/contact/toronto.svg";
import MapHollywood from "@media/contact/map_hollywood.svg";
import Hollywood from "@media/contact/hollywood.svg";
import MapStockholm from "@media/contact/map_stockholm.svg";
import Stockholm from "@media/contact/stockholm.svg";
import Form from "@page-components/contact/form";

const Location = ({ image, content, icon }) => (
  <div
    css={css`
      text-align: left;
      position: relative;
      box-shadow: 0 4px 10px 0 #c7cdcf;
      border-radius: 3px;
      height: 470px;
      padding-top: 110px;
    `}
  >
    {icon}
    <div
      css={css`
        padding: 10px;
      `}
    >
      {content}
    </div>
    <div
      css={css`
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 216px;
        background-image: url(${image});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      `}
    />
  </div>
);

const Contact = () => (
  <Layout page="contact">
    <Header
      title={<h1 style={{ fontWeight: "300" }}>Contact us</h1>}
      text="Send us a message or find the right contact"
    />
    <Form />
    <div
      className="max-width-4 mx-auto mb4"
      css={css`
        border-bottom: 1px solid #e1e3e4;
      `}
    ></div>
    <section className="py2 px2">
      <h2 className="center mb4">Our offices</h2>
      <div
        className="max-width-4 mx-auto pb4"
        css={css`
          border-bottom: 1px solid #e1e3e4;
        `}
      >
        <div className="clearfix mxn3">
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <Location
              image={MapToronto}
              icon={
                <>
                  <div
                    css={css`
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      height: 110px;
                      padding: 10px;
                    `}
                  >
                    <img src={Toronto} width="60" />
                  </div>
                </>
              }
              content={
                <>
                  <div
                    css={css`
                      margin-bottom: 10px;
                    `}
                  >
                    Toronto
                  </div>
                  208 Adelaide St W, 2nd Floor
                  <br />
                  Toronto, ON M5H 1W7 <br />
                  Canada
                  <br />
                  +1 647 478 8119
                </>
              }
            />
          </div>
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <Location
              image={MapHollywood}
              icon={
                <>
                  <div
                    css={css`
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      height: 110px;
                      padding: 10px;
                    `}
                  >
                    <img
                      src={Hollywood}
                      width="150"
                      css={css`
                        position: absolute;
                        bottom: 5px;
                      `}
                    />
                  </div>
                </>
              }
              content={
                <>
                  <div
                    css={css`
                      margin-bottom: 10px;
                    `}
                  >
                    Los Angeles
                  </div>
                  1640 S Sepulveda Blvd, Suite 103
                  <br />
                  Los Angeles, CA 90025
                  <br />
                  United States
                  <br />
                  +424 465 9114
                </>
              }
            />
          </div>
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <Location
              image={MapStockholm}
              icon={
                <>
                  <div
                    css={css`
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      height: 110px;
                      padding: 10px;
                    `}
                  >
                    <img
                      src={Stockholm}
                      width="110"
                      css={css`
                        position: absolute;
                        bottom: 5px;
                      `}
                    />
                  </div>
                </>
              }
              content={
                <>
                  <div
                    css={css`
                      margin-bottom: 10px;
                    `}
                  >
                    Stockholm
                  </div>
                  Dragrännan 2B
                  <br />
                  74650 Bålsta
                  <br />
                  Sweden
                  <br />
                  +46 730 808 402
                </>
              }
            />
          </div>
        </div>
      </div>
    </section>

    <section className="pt4 px2">
      <h2 className="center mb4">Get in touch</h2>
      <div className="max-width-4 mx-auto pb4">
        <div className="clearfix mxn3">
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <div
              className="mb2"
              css={css`
                font-size: 1.5em;
                font-weight: 700;
              `}
            >
              Sales
            </div>
            <a
              css={css`
                font-size: 1.1em;
                font-weight: 700;
                color: #0d98ff;
                &:hover {
                  color: #0d98ff;
                }
              `}
              href="mailto:aero@cinesend.com"
            >
              aero@cinesend.com
            </a>
          </div>
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <div
              className="mb2"
              css={css`
                font-size: 1.5em;
                font-weight: 700;
              `}
            >
              Careers
            </div>
            <a
              css={css`
                font-size: 1.1em;
                font-weight: 700;
                color: #0d98ff;
                &:hover {
                  color: #0d98ff;
                }
              `}
              href="mailto:careers@cinesend.com"
            >
              careers@cinesend.com
            </a>
          </div>
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <div
              className="mb2"
              css={css`
                font-size: 1.5em;
                font-weight: 700;
              `}
            >
              Public Relations
            </div>
            <a
              css={css`
                font-size: 1.1em;
                font-weight: 700;
                color: #0d98ff;
                &:hover {
                  color: #0d98ff;
                }
              `}
              href="mailto:pr@cinesend.com"
            >
              pr@cinesend.com
            </a>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

Contact.propTypes = {};

export default Contact;
